<template>
  <div class="gcs-footer">
    <div class="PC_father">
      <div class="center" style="width: 70%; margin: 0 auto">
        <div class="footer-top">
          <div style="width: 180px">
            <img :src="companydetail.companyLogo" alt="" style="width: 100%" />
          </div>
          <ul>
            <li>
              |<a style="cursor: default; padding-left: 5px">
                <!-- 资料库 -->
                {{ $t("global.Resource_Center") }}
              </a>
            </li>
            <li>
              <a @click="technoal" style="padding-left: 8px">
                <!-- 技术文献 -->
                {{ $t("global.Technical_Articles") }}
              </a>
            </li>
          </ul>
          <ul>
            <li>
              |
              <a style="cursor: default; padding-left: 5px">
                <!-- 关于我们 -->
                {{ $t("global.About_us") }}
              </a>
            </li>
            <li>
              <a @click="company('gaishu')">
                <!-- 公司概述 -->
                {{ $t("global.Overview") }}
              </a>
            </li>
            <li>
              <a @click="company('rongyu')">
                <!-- 公司荣誉 -->
                {{ $t("global.HONOR") }}
              </a>
            </li>
            <li>
              <a @click="getscheme">
                <!-- 获取方案 -->
                {{ $t("global.CONSULTING") }}
              </a>
            </li>
          </ul>
          <ul>
            <li>
              |
              <a style="cursor: default; padding-left: 5px">
                <!-- 联系方式 -->
                {{ $t("global.Contact_us") }}
              </a>
            </li>
            <li>
              <a style="cursor: default">
                <!-- 电话 -->
                {{ $t("global.Tel") }}： {{ companydetail.companyPhone }}</a
              >
              <!-- <a href="#">(010)67888629</a>  -->
            </li>
            <li>
              <a style="cursor: default">
                <!-- 市场合作： -->
                {{ $t("global.Marketing") }}：
                {{ companydetail.marketEmail }}</a
              >
            </li>
            <li>
              <a style="cursor: default">
                <!-- 人才招聘： -->
                {{ $t("global.Careers") }}：
                {{ companydetail.recruitmentEma }}</a
              >
            </li>
            <li>
              <a style="cursor: default">
                <!-- 售后支持： -->
                {{ $t("global.Technical") }}：
                {{ companydetail.afterSalesEma }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <el-divider></el-divider>
      <!-- <p style="font-size:12px;margin:0 auto">
        ©苏公网安备32021402003042号|苏ICP备2020068235号-1
      </p> -->
      <div style="display: flex; align-items: center; justify-content: center">
        <img
          src="@/assets/filingIcon.png"
          width="20"
          height="20"
          style="object-fit: contain"
        />
        <a
          style="font-size: 12px; margin-right: 5px"
          href="https://beian.mps.gov.cn/#/query/webSearch?code=32021402003042"
          rel="noreferrer"
          target="_blank"
          >苏公网安备32021402003042号</a
        >
        <a
          style="font-size: 12px"
          href="https://beian.miit.gov.cn/"
          rel="noreferrer"
          target="_blank"
          >苏ICP备2020068235号-1</a
        >
      </div>

      <div
        style="
          float: right;
          color: #fff;
          margin-right: 5px;
          font-size: 12px;
          color: #888;
          margin-top: 35px;
        "
      >
        V:Beta
      </div>
    </div>
    <div class="PE-father">
      <div class="center">
        <div class="footer-top">
          <div>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <img
                :src="companydetail.companyLogo"
                alt=""
                style="width: 20%; max-width: 180px"
              />
              <span
                style="
                  color: aliceblue;
                  font-size: 25px;
                  text-align: center;
                  margin-left: -16px;
                "
              >
                {{ companydetail.companyName }}
              </span>
            </div>
            <div style="margin-top: 10px; padding-left: 20px">
              <img
                src="../../assets/Wx_Tang.jpg"
                style="width: 50%; max-width: 180px"
              />
            </div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <!-- <p>©苏公网安备32021402003042号|苏ICP备2020068235号-1</p> -->
      <div style="display: flex; align-items: center; justify-content: center">
        <img
          src="@/assets/filingIcon.png"
          width="20"
          height="20"
          style="object-fit: contain"
        />
        <a
          style="font-size: 12px; margin-right: 5px"
          href="https://beian.mps.gov.cn/#/query/webSearch?code=32021402003042"
          rel="noreferrer"
          target="_blank"
          >苏公网安备32021402003042号</a
        >
        <a
          style="font-size: 12px"
          href="https://beian.miit.gov.cn/"
          rel="noreferrer"
          target="_blank"
          >苏ICP备2020068235号-1</a
        >
      </div>
      <div
        style="
          float: right;
          color: #888;
          margin-right: 5px;
          font-size: 12px;
          margin-top: 35px;
        "
      >
        V:Beta
      </div>
    </div>
    <ConnectUs ref="aboutus" v-show="false" />
  </div>
</template>

<script>
import ConnectUs from "../../views/front/ConnectUs.vue";
export default {
  components: {
    ConnectUs,
  },
  data() {
    return {
      companydetail: {},
    };
  },
  mounted() {
    this.request.get("/company/selectParentCompany").then((res) => {
      // this.options = res.data
      if (res.code == "200") {
        this.companydetail = res.data;
      }
    });
  },
  methods: {
    company(d) {
      //
      // this.$store.commit("aboutUs", d);
      this.$router.push("/connectUs");
      this.$refs.aboutus.toLocal(d);
    },
    technoal() {
      this.$router.push("/techliteratural");
    },
    getscheme() {
      this.$router.push("/getmethods");
    },
  },
};
</script>

<style scoped="less">
* {
  font-family: "普惠字体";
}

.gcs-footer {
  /* margin-top: 20px; */

  background: rgb(60, 58, 59);

  width: 100%;

  text-align: center;

  color: white;

  position: absolute;
}

.gcs-footer .footer-top {
  /* float: left; */
  margin-top: 30px;
  display: flex;
  /* justify-items: ; */
  justify-content: space-between;
}

.gcs-footer a {
  color: #f0f1f1;

  text-decoration: none;

  padding: 0 10px;

  vertical-align: middle;
}

.gcs-footer a:hover {
  /* color: red; */

  text-decoration: underline;
}

.gcs-footer p {
  height: 15px;

  color: #888888;

  margin-top: 30px;
}

ul {
  float: left;
}

li {
  list-style: none;
  margin-top: 10px;
  text-align: left;
  font-size: 12px;
}

a:hover {
  /* text-decoration: none; */
  cursor: pointer;
  text-decoration: none !important;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

@media (min-width: 668px) {
  .PE-father {
    display: none;
  }
}

@media (max-width: 668px) {
  .PC_father {
    display: none;
  }

  .PE-father {
    width: 100%;
    background-color: rgb(60, 58, 59);
    color: white;
    text-align: center;
  }

  .PE-father .center {
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .PE-father .footer-top {
    margin-bottom: 30px;
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .PE-father .footer-top img {
    width: 100%;
    max-width: 180px;
    margin-right: 20px;
  }

  .PE-father p {
    font-size: 12px;
    color: #888888;
    margin-top: 30px;
  }
}
</style>

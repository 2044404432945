export default {
    global: {
        fazhan:"发展历程",
        gongsi:"公司概述",
        lianxi:"联系我们",
        zizhi:"资质荣誉",
        chanpin:"产品服务",
        News:"新闻资讯",
        home: '首页',
        more: '更多',
        Resource_Center: '资料库',
        Technical_Articles: '技术文献',
        About_us: '关于我们',
        Overview: '公司概括',
        HONOR: '公司荣誉',
        Consulting: '获取方案',
        Contact_us: '联系我们',
        Name: '姓名',
        Tel: '联系方式',
        tel:'电话',
        Company_JobTitle: '公司及职位',
        Business_Email: '公司邮箱',
        Marketing: '市场合作',
        Careers: '人才招聘',
        Technical: '技术支持',
        QualifIcation: '企业资质',
        Patent: '专利荣誉',
        Copyright: '软件著作',
        Recruitment: '热招岗位',
        CONSULTING: '获取方案',
        hqfa: '感谢您来到汤谷智能，请留下您的需求我们将尽快与您联系',
        Please_describe: '需求概括',
        zip_code:'邮编',
        address:'地址',
        items:'项',
        Technical_Articles:"技术文献",
        Technical_title:'如需阅读文件请点击:',

        butt: {
            More:'查看详情',
            moremore: '查看更多',
            // technicalLiterature: '技术文献',
            next: '下一篇',
            Prev: '上一篇',
            Back: '返回',
            Submit: '提交',
            first:'当前是第一篇',
            last:'当前是最后一篇',
            Refreshing:'刷新',
            Verification_code:"验证码",
            Submitted_successfully:'提交成功'
        },
        title:{
            NameTips:'请输入您的姓名',
            NameTips1:"请输入正确的姓名（1-9位英文字母或者汉字）",
            telTips:'请输入联系方式',
            telTips1:'请输入正确联系方式(1开头,11位数字)',
            emailTips:'请输入邮箱',
            emailTips1:'请输入正确邮箱',
            JobTips:'请输入公司和职位',
            JobTips1:'公司和职位请在2-15个字符',
            describeTips:'请输入需求概述',
            describeTips1:'请输入正确的需求概述（100字符以内）',
        }
    }
}
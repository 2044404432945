<template>
  <el-container>
    <el-header style="padding: 0; height: 90px">

      <Head />
    </el-header>
    <el-main style="padding: 0">
      <!-- <v-if= Home/> -->
      <router-view @load="loadHandle"></router-view>
      <el-backtop />
    </el-main>
    <el-footer style="padding: 0">
      <Footer />
    </el-footer>
  </el-container>
</template>

<script>
import Footer from "@/components/front/footer.vue";
import Home from "@/views/front/Home.vue";
import Head from "@/components/front/header.vue";
import cookies from "js-cookie";

import i18n from "../../lang";
export default {
  name: "frontManage",
  components: {
    Footer,
    Head,
    Home,
  },

  beforeMount() {
    this.request.post("/menu/getTouristMenu").then((res) => {
      if (res.code) {
        localStorage.setItem("frontMenus1", JSON.stringify(res.data));
        //  console.log("aaa",JSON.parse(localStorage.getItem("frontMenus1")))
      }
    });
  },
  methods: {
    loadHandle() {
      window.scrollTo(0, 0); // 将滚动位置设置为最顶部
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  overflow: hidden;
}

@media (max-width: 668px) {
  /deep/.el-header {
    height: 75px !important;
  }
}
</style>

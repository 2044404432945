import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入全局css样式
import './assets/gloable.css'
// 引入request.js
import request from "@/utils/request";
import store from './store/store'
import i18n from './lang/index.js'
// main.js全局注册
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import axios from 'axios';
import cookies from 'js-cookie'
import './assets/font.css' 
//挂载

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'

import VueLazyLoad from 'vue-lazyload' // 全局引入vue-lazyload
 
Vue.use(VueLazyLoad)

let options = {
    fullscreenEl: false,
  }
  Vue.use(preview, options)  
Vue.use(preview)

// import '../src/lang/hand.js'
// import ElementLocale from 'element-ui/lib/locale'
Vue.use(mavonEditor)

// axios.defaults.baseURL = "https://www.macrone.com.cn:9090"; 
axios.defaults.baseURL = window.g.baseURL;
// baseURL: 'https://www.tangoic.com:9090',
// axios.defaults.baseURL = "http://192.168.0.39:9090";
Vue.config.productionTip = false;

// 设置大小，适配屏幕
Vue.use(ElementUI, {
size: "mini",
i18n: (key, value) => i18n.t(key, value),

});

// 引入request.js
Vue.prototype.request = request
Vue.prototype.$axios = axios;

new Vue({
    router,
    store,
    i18n,
    cookies,
    render: h => h(App)
}).$mount('#app')



export default {
    global: {
        fazhan:"MILESTONES",
        gongsi:"OVERVIEW",
        lianxi:"CONTACT US",
        zizhi:"QUALIFICATION HONOR",
        chanpin:"HOT PRODUCT",
        News:"NEWS",
        home: 'Home',
        more: 'more',
        Resource_Center: 'Resource Center',
        Technical_Articles: 'Technical Articles',
        About_us: 'About us',
        Overview: 'Overview',
        HONOR: 'Honor',
        Consulting: 'Consulting',
        Contact_us: 'Contact us',
        Name: 'Name',
        Tel: 'Tel',
        tel:'Tel',
        Company_JobTitle: 'Company & Job Title',
        Business_Email: 'Business Email',
        Marketing: 'Marketing',
        Careers: 'Careers',
        Technical: 'Technical',
        QualifIcation: 'QualifIcation',
        Patent: 'Patent',
        Copyright: 'Copyright',
        Recruitment: 'Recruitment',
        CONSULTING: 'Consulting',
        hqfa:'Thank you for coming to Tango Intelligence, please leave your needs and we will contact you as soon as possible',
        Please_describe: 'Please describe',
        zip_code:'Zip code',
        address:'Address',
        items:'items',
        Technical_Articles:"Technical Articles",
        Technical_title:'To read the document, please click:',
        butt: {
            More:'more',
            moremore: 'more',
            next: 'Next',
            Prev: 'Prev',
            Back: 'Back',
            Submit: 'Submit',
            first:'This is the first one',
            last:'Currently the last article',
            Refreshing:'Refreshing',
            Verification_code:"Verification code",
            Submitted_successfully:'Submitted successfully!'
        },
        title:{
            NameTips:'Please enter your name',
            NameTips1:'Please enter the correct name (1-9 English letters or Chinese characters)',
            telTips:'Please enter your phone number',
            telTips1:'Please enter the correct phone number',
            emailTips:'Please enter your Business Email',
            emailTips1:'Please enter the correct  Business Email',
            JobTips:'Please enter the Company and Job Title',
            JobTips1:'Please enter the correct Company and Job Title',
            describeTips:'Please enter the Please describe',
            describeTips1:'Please enter the correct Please describe(within 100 characters)',
        }
    }
}

<template>
    <div class="container">
      <div class="content">
        <!-- <div class="coin" v-for="(item,index1) in Math.ceil((list.length+1)/4)" :key="index1" v-show="isShow==index1"> -->
        <div class="coin" v-for="(item,index1) in list" :key="index1" v-show="isShow==index1">
          <div class="xs_one"></div>
          <div
            class="coinAll"
            v-for="(item, index) in list"
            :key="index"
            :class="{ one: index%4==0 }"
            v-show="index<(index1+1)*4 && index+1>(index1)*4"
          >
            <span
              :class="{
                two: (index + 1) % 2 == 0,
                text_active: index == isIndex,
              }"
            >
              {{ item }}
            </span>
            <div class="xs" :class="{ xs_active: index == isIndex }"></div>
            <!-- <img src="../assets/img/coin1.png" alt="" v-show="index == isIndex" /> -->
            <!-- <img src="../assets/img/coin2.png" alt="" v-show="index != isIndex" /> -->
          </div>
        </div>
        <!-- <div class="paging" :style="{width:Math.ceil((list.length+1)/4)*20+'px'}">
          <div class="pagings" v-for="(item,index1) in Math.ceil((list.length+1)/4)" :key="index1" @click="tabList(index1)" :class="{is_activ:isShow==index1}">{{index}}</div>
        </div> -->
      </div>
    </div>
  </template>
   
   
  <script>
  export default {
    data() {
      return {
        list: ["实施方案", "任务书", "中期检查", "项目验收", "分页功能1","分页功能1","分页功能1","分页功能1","分页功能2"], //列表
        isIndex: 0, //高亮显示
        isShow: 1, //显示
      };
    },
    methods:{
      tabList(idx){
        this.isShow=idx
      }
    }
  };
  </script>
   
   
  <style scoped>
  .content {
    width:800px;
    height: 120px;
    background: rgb(9, 27, 70);
    position: relative;
  }
  .paging{
    height: 20px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%);
  }
  .paging div{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: rgb(120, 120, 120);
    float: left;
    margin-left: 5px;
  }
  .coin {
    width: 100%;
    height: 80px;
    background: rgb(9, 27, 70);
    position: relative;
    overflow-x: hidden;
  }
  .xs_one {
    width: 100%;
    height: 5px;
    background: rgb(61, 183, 270);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
  .coin .coinAll {
    width: 100px;
    height: 100%;
    float: left;
    position: relative;
  }
   
  .coin .one {
    margin-left: -50px;
  }
  .coin .coinAll img {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }
  .coin .coinAll .xs {
    width: 100%;
    height: 5px;
    background: rgb(61, 183, 270);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
   
  .coin .coinAll span {
    width: 100%;
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 5px;
  }
  .coin .coinAll .two {
    width: 100%;
    color: #fff;
    font-size: 16px;
    position: absolute;
    top: 55px;
  }
   
  /* 文字高亮 */
  .coin .coinAll .text_active {
    color: rgb(245, 189, 39);
  }
   
  /* 线条高亮 */
  .coin .coinAll .xs_active {
    background: rgb(245, 189, 39);
  }
  .paging .is_activ{
    background: rgb(26, 82, 229);
  }
  </style>
<template>
  <div class="new">
    <div class="banner" style="width: 100%; margin: 0 auto">
      <img :src="path" style="width: 100%" />
    </div>
    <div class="PC_father">
      <div class="center">
        <span style="font-size:14px;cursor:pointer;" @click="toHome">{{ $t("global.home") }}&gt;</span>
        <span>{{ $t('global.About_us') }}</span>
        <div class="company_profile">
          <h2 v-if="$t('global.gongsi') != 'OVERVIEW'">OVERVIEW</h2>
          <div style="clear: both" class="tit">
            <span class="xian"></span>
            <h1 id="gaishu" ref="gaishu">{{ $t('global.gongsi') }}</h1>
            <span class="xian"></span>
          </div>
          <div class="left11" style="width: 60%; float: left; max-width:500px">
            <mavon-editor class="md" :value="companyDetail.companyDescription" :subfield="false" :defaultOpen="'preview'"
              :toolbarsFlag="false" :editable="false" :scrollStyle="true" :ishljs="true" />
          </div>
          <div class="img" style="width: 33%; float: right; height: 17vw;max-height:210px;">
            <img v-lazy="imgObj" :src="companyDetail.companyDescriptionImg" alt="" style="width: 100%; height: 100%" />
          </div>
        </div>
      </div>
      <div class="development" style="width: 100%; text-align: center">
        <h2 v-if="$t('global.fazhan') != 'MILESTONES'">MILESTONES</h2>
        <div style="clear: both" class="tit">
          <span class="xian"></span>
          <h1 id="develop" ref="develop">{{ $t('global.fazhan') }}</h1>
          <!-- <timeZhou/> -->
          <span class="xian"></span>
        </div>
        <div style="width:100%;">
          <img :src="companyDetail.developingCourse" alt="" style="margin: 0 auto;width:100%" />
        </div>
      </div>
      <div id="rongyu" class="honor" style="width: 75%; padding-bottom: 50px">
        <h2 v-if="$t('global.zizhi') != 'QUALIFICATION HONOR'">QUALIFICATION HONOR</h2>
        <div style="clear: both" class="tit">
          <span class="xian"></span>
          <h1 ref="rongyu">{{ $t('global.zizhi') }}</h1>
          <span class="xian"></span>
        </div>
        <div class="left" style="margin-top:30px">
          <img :src="honor.companyHonorImg" alt="" />
        </div>
        <div class="right">
          <p class="top" style="padding-top:0px">
            {{ honor.companyHonorInfo }}
          </p>
          <div class="cen">
            <div>
              <h2>{{ honor.companyAlbum }}{{ $t('global.items') }}</h2>
              <span>
                <!-- 企业资质 -->
                {{ $t('global.QualifIcation') }}
              </span>
            </div>
            <div>
              <h2>{{ honor.companyPatent }}{{ $t('global.items') }}</h2>
              <span>
                <!-- 国家专利 -->
                {{ $t('global.Patent') }}
              </span>
            </div>
            <div>
              <h2>{{ honor.companySoftwa }}{{ $t('global.items') }}</h2>
              <span>
                <!-- 软件著作 -->
                {{ $t('global.Copyright') }}
              </span>
            </div>
          </div>
          <div class="bott">
            <div @click="changeImg">
              <!-- 企业资质 -->
              {{ $t('global.QualifIcation') }}
            </div>
            <div @click="changeImg">
              <!-- 专利荣誉 -->
              {{ $t('global.Patent') }}
            </div>
            <div ref="softw" @click="changeImg">
              <!-- 软件著作 -->
              {{ $t('global.Copyright') }}
            </div>
          </div>
        </div>
      </div>

      <div class="showimg" style="width:1300px;height:380px;padding-top:50px;overflow-x:auto;margin:0 auto"
        v-if="honerShow">
        <ul style="width:100%;height:90%;">
          <li class="listl" v-for="item in currentPageData" @mouseover="clicklist(item)" @mouseleave="closeImg"
            :key="item.id" style="width: 170px;float:left;padding-left:10px">
            <img :src="item" alt="" style="width: 100%; height: 100%" />
          </li>
        </ul>

        <!-- 控制分页 -->
        <el-button @click="prevPage" :disabled="currentPage === 0" style="position:absolute;left:10px;bottom:0px">
          <!-- 上一页 -->
          {{ $t('global.butt.Prev') }}
        </el-button>
        <el-button @click="nextPage" :disabled="currentPage >= pageCount - 1"
          style="position:absolute;left:110px;bottom:0px">
          <!-- 下一页 -->
          {{ $t('global.butt.next') }}
        </el-button>
      </div>

      <div class="conncetus" style="width: 75%;max-width:920px; padding-bottom: 50px">
        <h2 v-if="$t('global.lianxi') != 'CONTACT US'">CONTACT US</h2>
        <div style="clear: both" class="tit">
          <span class="xian"></span>
          <h1 id="connect" ref="connect">{{ $t('global.lianxi') }}</h1>
          <span class="xian"></span>
        </div>
        <div class="top1" style="" v-for="(item, index) in tableDatacompany" :key="index">
          <div class="detailleft">
            <h3 style="
                text-align: center;
                border-bottom: 1px dashed #999;
                line-height: 50px;
              ">
              {{ item.companyName }}
            </h3>
            <p class="email" style="border-bottom: 1px dashed #999">
              <!-- 邮编 -->

              {{ $t('global.zip_code') }}：{{ item.postcode }}
            </p>
            <p class="phone">
              <!-- 电话 -->

              {{ $t('global.Tel') }}：{{ item.companyPhone }}
            </p>
            <p class="address">
              <!-- 地址 -->
              {{ $t('global.address') }}：{{ item.address }}
            </p>
          </div>
          <div class="imgtop">
            <img :src="item.addressImg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="PE_father">
      <div class="center">
        <div class="company_profile">
          <h2 v-if="$t('global.gongsi') != 'OVERVIEW'">OVERVIEW</h2>
          <div style="clear: both" class="tit">
            <span class="xian"></span>
            <h1 id="gaishu" ref="gaishu">{{ $t('global.gongsi') }}</h1>
            <span class="xian"></span>
          </div>
          <div class="left11" style="width: 60%; float: left; max-width:500px">
            <mavon-editor class="md" :value="companyDetail.companyDescription" :subfield="false" :defaultOpen="'preview'"
              :toolbarsFlag="false" :editable="false" :scrollStyle="true" :ishljs="true" />
          </div>
          <div class="img" style="width: 33%; float: right; height: 17vw;max-height:210px;">
            <img preview="0" preview-text="描述" :src="companyDetail.companyDescriptionImg" alt=""
              style="width: 100%; height: 100%" />
          </div>
        </div>
      </div>

      <div class="development" style="width: 100%; text-align: center">
        <h2 v-if="$t('global.fazhan') != 'MILESTONES'">MILESTONES</h2>
        <div style="clear: both" class="tit">
          <span class="xian"></span>
          <h1 id="develop" ref="develop">{{ $t('global.fazhan') }}</h1>
          <!-- <timeZhou/> -->
          <span class="xian"></span>
        </div>
        <div style="width:100%;margin-top:20px">
          <img preview="0" :src="companyDetail.developingCourse" alt="" style="margin: 0 auto;width:100%" />
        </div>
      </div>
      <div id="rongyu" class="honor" style="width: 75%; padding-bottom: 50px">
        <h2 v-if="$t('global.zizhi') != 'QUALIFICATION HONOR'">QUALIFICATION HONOR</h2>
        <div style="clear: both" class="tit">
          <span class="xian"></span>
          <h1 ref="rongyu">{{ $t('global.zizhi') }}</h1>
          <span class="xian"></span>
        </div>
        <div class="right">
          <p class="top" style="padding-top:0px">
            {{ honor.companyHonorInfo }}
          </p>
          <div class="cen">
            <div>
              <h2>{{ honor.companyAlbum }}{{ $t('global.items') }}</h2>
              <span>
                <!-- 企业资质 -->
                {{ $t('global.QualifIcation') }}
              </span>
            </div>
            <div>
              <h2>{{ honor.companyPatent }}{{ $t('global.items') }}</h2>
              <span>
                <!-- 国家专利 -->
                {{ $t('global.Patent') }}
              </span>
            </div>
            <div>
              <h2>{{ honor.companySoftwa }}{{ $t('global.items') }}</h2>
              <span>
                <!-- 软件著作 -->
                {{ $t('global.Copyright') }}
              </span>
            </div>
          </div>
          <div class="bott">
            <div @click="changeImg">
              <!-- 企业资质 -->
              {{ $t('global.QualifIcation') }}
            </div>
            <div @click="changeImg">
              <!-- 专利荣誉 -->
              {{ $t('global.Patent') }}
            </div>
            <div ref="softw" @click="changeImg">
              <!-- 软件著作 -->
              {{ $t('global.Copyright') }}
            </div>
          </div>
        </div>
      </div>

      <div class="PE_showimg" v-if="honerShow">
        <div class="PE_img-container">
          <ul>
            <li style=list-style:none; class="PE_listl" v-for="item in currentPageData" :key="item.id">
              <img preview="1" preview-text="描述" :src="item" alt="" />
            </li>

          </ul>
        </div>

        <div class="PE_pagination">
          <el-button @click="prevPage" :disabled="currentPage === 0">
            上一页
          </el-button>
          <el-button @click="nextPage" :disabled="currentPage >= pageCount - 1">
            下一页
          </el-button>
        </div>
      </div>

      <div class="conncetuss" style="width: 75%;max-width:920px; padding-bottom: 50px">
        <h2 v-if="$t('global.lianxi') != 'CONTACT US'">CONTACT US</h2>
        <div style="clear: both" class="tit">
          <span class="xian"></span>
          <h1 id="connect" ref="connect">{{ $t('global.lianxi') }}</h1>
          <span class="xian"></span>
        </div>
        <div class="top1" style="" v-for="(item, index) in tableDatacompany" :key="index">
          <div class="detailleft">
            <h3 style="
                text-align: center;
                border-bottom: 1px dashed #999;
                line-height: 50px;
              ">
              {{ item.companyName }}
            </h3>
            <p class="email" style="border-bottom: 1px dashed #999">
              <!-- 邮编 -->

              {{ $t('global.zip_code') }}：{{ item.postcode }}
            </p>
            <p class="phone">
              <!-- 电话 -->

              {{ $t('global.Tel') }}：{{ item.companyPhone }}
            </p>
            <p class="address">
              <!-- 地址 -->
              {{ $t('global.address') }}：{{ item.address }}
            </p>
          </div>
          <div class="imgtop">
            <img :src="item.addressImg" alt="" />
            <!-- <div class="mapContainer" id="map-container"></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader"; // 按需引入依赖
window._AMapSecurityConfig = {
  securityJsCode:"7a99c0dea407238b1113cedd527fda3d", // 安全密钥
};
import timeZhou from '@/components/front/timezhou.vue'
// import lunbo from "@/components/front/lunbo.vue";

export default {
  components: {
    timeZhou
    // lunbo
  },

  data() {
    return {
      map: null,
      mouseTool : null,
      overlays : [],
      auto : null,
      placeSearch : null, 
      imgObj: {
        src: '', // 真正的图片地址
      },
      lazyLoad: true,
      sectionId: '',
      path: '',
      showList: false,
      currentPage: 0, // 当前页数
      pageSize: 7, // 每页显示的数据量
      honerShow: false,
      honor: {},
      companyDetail: {},
      ruleForm: {
        name: '',
        phone: '',
        job: '',
        mail: '',
        demand: ''
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        job: [{ required: true, message: '请输入公司及职位', trigger: 'blur' }],
        mail: [{ required: true, message: '请输入公司邮箱', trigger: 'blur' }],
        demand: [{ required: true, message: '请输入需求概括', trigger: 'blur' }]
      },
      url: '',
      article: {},
      img: [],
      tableDatacompany: [],
      urlitem: ''
    }
  },
  computed: {
    // 计算当前页的数据
    currentPageData() {
      // JavaScript 的 slice 方法可以用来获取数组的一个子序列，这里用来获取当前页的数据
      return this.img.slice(this.currentPage * this.pageSize, (this.currentPage + 1) * this.pageSize)
    },
    // 计算总页数
    pageCount() {
      return Math.ceil(this.img.length / this.pageSize)
    }
  },
  watch: {
    '$store.state.aboutus': {
      // immediate: true,
      handler(val) {
        // console.log('1232132发生变化了')
        // console.log(this.$store.state.aboutus)
        this.goToSection(val)
      }
    }
  },
  mounted() {
    this.initMap();
    // console.log(window.pageYOffset)
    // window.scrollTo(0, window.pageYOffset-150);
    // window.addEventListener("scroll", this.handleScroll);
    this.huoquHonor()
    this.gongsigaishu()
    this.getcompany()
    this.huoqu()
    this.$previewRefresh();
  },
  methods: {
    initMap() {
    AMapLoader.load({
        "key": "4c1333a4be7bf72c5b683df714e3a01d", // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": ["AMap.Scale","AMap.ToolBar","AMap.ControlBar","AMap.MouseTool","AMap.MapType","AMap.HawkEye"],    // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    })
    .then((AMap)=>{
        this.map = new AMap.Map('map-container',{
            viewMode : "2D",  //  是否为3D地图模式
            zoom : 13,   // 初始化地图级别
            center : [116.58,39.79], //中心点坐标
            resizeEnable: true
        });
        // 添加左下角的比例尺
        this.map.addControl(new AMap.Scale());   
        // 鼠标点击获取经纬度
        this.map.on('click', function(e) {
            console.log('e---',e)
            console.log('e.lnglat---',e.lnglat)
            console.log("经度",e.lnglat.getLng())
            console.log("纬度",e.lnglat.getLat())
        });
        // 地图上绘制点位
        this.mouseTool = new AMap.MouseTool(this.map);
        // 监听draw事件可获取画好的覆盖物
        this.mouseTool.on('draw',function(e){
            console.log('获取画好的覆盖物',e)
            this.overlays.push(e.obj);
        }.bind(this))
        this.map.addControl(new AMap.MapType());   // 添加右上角的标准图和卫星图  和路况
        this.map.addControl(new AMap.HawkEye());   // 添加地图放大镜

        // 添加定位图标
        const markerIcon = new AMap.Icon({
            image: 'https://a.amap.com/jsapi_demos/static/images/marker-icon-red.png', // 自定义定位图标，这里使用了一个红色的图标
            size: new AMap.Size(25, 34)
        });
        const marker = new AMap.Marker({
            position: [116.58,39.79], // 定位图标的位置
            map: this.map
        });
    }).catch(e => {
        console.log(e);
    });
},
          // 具体画图代码
          draw(type){
            switch(type){
                case 'marker':{
                    this.mouseTool.marker({
                        //同Marker的Option设置
                    });
                    break;
                }
                case 'polyline':{
                    this.mouseTool.polyline({
                        strokeColor:'#80d8ff'
                        //同Polyline的Option设置
                    });
                    break;
                }
                case 'polygon':{
                    this.mouseTool.polygon({
                        fillColor:'#00b0ff',
                        strokeColor:'#80d8ff'
                        //同Polygon的Option设置
                    });
                    break;
                }
                case 'rectangle':{
                    this.mouseTool.rectangle({
                        fillColor:'#00b0ff',
                        strokeColor:'#80d8ff'
                        //同Polygon的Option设置
                    });
                    break;
                }
                case 'circle':{
                    this.mouseTool.circle({
                        fillColor:'#00b0ff',
                        strokeColor:'#80d8ff'
                        //同Circle的Option设置
                    });
                    break;
                }
            }
        },
    toHome() {
      this.$router.push('/home')
    },
    goToSection(menuName) {
      // console.log
      switch (menuName) {
        case 'gaishu':
          this.sectionId = menuName
          this.next(this.setionId)
          break

        case 'develop':
          this.sectionId = menuName
          this.next(this.setionId)
          break
        case 'rongyu':
          this.sectionId = menuName
          this.next(this.setionId)
          break
        case 'connect':
          this.sectionId = menuName
          // 获取目标元素的位置
          this.next(this.setionId)
          break

        default:
          break
      }
    },
    next(a) {
      var sectionElement = this.$refs[this.sectionId]
      var sectionPosition = sectionElement.offsetTop

      // 滚动到目标位置
      // this.$nextTick(() => {
      if (sectionPosition) {
        console.log('执行了', sectionPosition)
        window.scrollTo(0, sectionPosition - 150)
      }
    },

    huoqu() {
      this.request
        .get('/FlatPattern/getMenuById', {
          params: {
            id: localStorage.getItem('iddMenu')
          }
        })
        .then((res) => {
          // console.log(res);
          if (res.data.flatPattern[0].url) {
            this.path = res.data.flatPattern[0].url
          }
        })
    },

    clicklist(item) {
      this.showList = true
      this.urlitem = item
    },
    closeImg() {
      this.showList = false
    },
    // 上一页
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--
      }
    },
    // 下一页
    nextPage() {
      if (this.currentPage < this.pageCount - 1) {
        this.currentPage++
      }
    },
    // 滑动
    toLocal(Id) {
      //查找存储的锚点id
      this.$nextTick(() => {
        let toElement = document.getElementById(Id)
        //锚点存在跳转
        if (Id) {
          toElement.scrollIntoView()
        }
      })
    },
    getcompany() {
      this.request
        .get('/company/listPage', {
          params: {
            pageNum: 1,
            pageSize: 5
          }
        })
        .then((res) => {
          // console.log('联系我们',res);

          var listcompany = res.data
          listcompany.forEach((item) => {
            if (item.parentCompany == 1) {
              this.tableDatacompany.push(item)
            }
          })
        })
    },
    changeImg(e) {
      this.currentPage = 0
      if (e.target.innerText) {
        this.honerShow = true
        this.img = []
        let arr = this.honor.honorInfos
        // console.log("荣誉", arr);
        arr.forEach((item) => {
          if (item.HonorName == e.target.innerText) {
            item.children.forEach((i) => {
              this.img.push(i.url)
            })
          }
        })
        // console.log("荣誉图片", this.img);
      }
    },
    huoquHonor() {
      this.request
        .get('/CompanyInfoFile/list', {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          // console.log('荣誉',res);
          this.honor = res.data
        })
    },

    gongsigaishu() {
      this.request.get('/company/selectParentCompany').then((res) => {
        if (res.code == '200') {
          this.companyDetail = res.data
          this.imgObj = this.companyDetail.companyDescriptionImg
          // console.log("母公司",this.companyDetail)
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.request({
            url: '/comment/save',
            method: 'post',
            data: {
              companyAndPosition: this.ruleForm.job,
              companyMail: this.ruleForm.mail,
              content: this.ruleForm.demand,
              name: this.ruleForm.name,
              phone: this.ruleForm.phone
            }
          }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                message: '信息提交成功!',
                type: 'success'
              })
              this.$refs[formName].resetFields()
            }
          })
        } else {
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>


.mapContainer {
  width: 100%;
  height: 100%;
}
.imgg {
  width: 250px;
  position: absolute;
  left: calc(50% - 125px);
  cursor: pointer;
}

ul {
  position: absolute;
  width: 100%;
  overflow: auto;

  .listl {
    overflow-x: auto;
    white-space: nowrap;
    margin-top: 10px;
  }

  .listl:hover {
    // box-shadow: 0 1px 7px 0 rgba(0,0,0,0.3);
    cursor: pointer;

    // width:250px !important;
  }

  .listl>img:hover {
    width: 250px !important;
    position: absolute !important;
    z-index: 999 !important;
  }

  .listl:nth-child(7) img:hover {
    width: 250px !important;
    position: absolute !important;
    right: 100px !important;
  }
}

.new {
  margin-bottom: 200px;
}

.timeline-container {
  overflow-x: auto;
  white-space: nowrap;
  /* 确保时间轴项目在一行上显示 */
  width: 100%;
  /* 设置容器的宽度，根据需要进行调整 */
}

.el-timeline-item {
  display: inline-block;
  /* 将项目以行内块元素的形式显示 */
  vertical-align: top;
  /* 调整垂直对齐方式，根据需要进行调整 */
}

#getmethod {
  width: 50%;
  margin: 30px auto;

  /deep/.el-form-item {
    background-color: #e5e5e5;
    border: 1px solid #7e8ea7;
    border-width: 2px;
    border-radius: 5px;

    .el-form-item__label {
      padding: 0 10px;
      font-size: 12px;
    }

    .el-input__inner {
      background-color: #e5e5e5 !important;
      border: none;
    }
  }

  .hang {
    display: flex;
    justify-content: space-between;

    .el-form-item {
      width: 40%;
    }
  }

  /deep/.el-textarea {
    .el-textarea__inner {
      min-height: 180px !important;
      background-color: #e5e5e5;
      border: none;
    }
  }

  p {
    text-align: center;

    .el-button {
      background-color: #e5e5e5;
      color: #666;
      border: 1px solid #7e8ea7;
      border-width: 2px;
      border-radius: 5px;
      padding: 10px 50px;
    }
  }
}

.email {
  padding: 10px;
}

.address {
  padding-left: 10px;
  padding: 10px;
}

.phone {
  padding-left: 10px;
  padding-right: 10px;
  padding: 10px;
  border-bottom: 1px dashed #999;
}

.conncetus {
  margin: 0 auto;
  margin-top: 80px;
}

.top1 {
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
  height: 250px;

  .detailleft {
    width: 46%;
    height: 100%;
    border: 2px solid #999;

    p {
      font-size: 14px;
    }
  }

  .imgtop {
    width: 46%;
    height: 100%;
    border: 2px solid #999;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

/deep/.medium {
  width: 100px !important;
}

.tit {
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 50px;

  h1 {
    display: inline;
  }

  .xian {
    display: inline-block;
    width: 36px;
    height: 4px;
    background-color: rgb(18, 79, 181);
  }
}

.company_profile {
  width: 100%;
  clear: both;
  margin-top: 80px;
}

.center {
  width: 75%;
  max-width: 913px;
  overflow: hidden;
  clear: both;
  margin: 0 auto;
  padding-top: 20px;

  >span {
    font-size: 14px;
  }
}

h2 {
  display: block;
  width: auto;
  text-align: center;
}

/deep/.v-note-wrapper.shadow {
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px !important;
  width: 100%;
}

/deep/.v-note-wrapper .v-note-panel .v-note-show .v-show-content,
.v-note-wrapper .v-note-panel .v-note-show .v-show-content-html {
  background-color: #fff !important;
  width: 100% !important;
}

/deep/.v-note-wrapper .v-note-panel {
  width: 100% !important;
  float: left !important;
}

.img {
  width: 15%;
  float: left;
  height: 30px;
  border: 1px #000 solid;
  margin-top: 31px;
}

.development {
  padding-top: 30px;
  // background-color: rgb(234, 234, 234);
  width: 100%;
}

.honor {
  width: 65%;
  margin: 0 auto;
  margin-top: 100px;
  overflow: hidden;
  clear: both;

  .left {
    width: 40%;
    height: 20vw;
    float: left;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .left11 {
    margin-top: 30px;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 50px;
    width: 50%;
    float: left;
    margin-top: 30px;

    .top {
      width: 100%;
      padding: 10px;
      border-bottom: 1px solid #999;
    }

    .cen {
      padding: 50px 20px;
      padding-left: 10px;
      display: flex;
      justify-content: space-between;

      h2 {
        color: rgb(18, 79, 181);
        text-align: left;
      }

      div {
        width: 100px;
      }
    }

    .bott {
      width: 100%;
      display: flex;
      justify-content: space-between;
      // padding: 20px;
      padding: 0 20px 0 10px;

      div {
        width: 120px;
        border: 2px solid rgb(18, 79, 181);
        color: rgb(18, 79, 181);
        font-size: 14px;
        text-align: center;
        padding: 5px 0px;
        border-radius: 8px;
        //font-weight: 700;
        // margin-right: 63px;
        // display: inline-block;
        cursor: pointer;
      }
    }
  }
}

.showimg {
  position: relative;
  overflow: hidden;
  clear: both;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  text-align: center;
}

@media (min-width: 668px) {
  .PE_father {
    display: none;
  }
}

@media (max-width: 668px) {
  /deep/.v-note-wrapper {
    position: static !important;
  }

  .new {
    margin-bottom: 20px;
  }

  .PE_showimg {
    width: 100%;
    height: 380px;
    padding-top: 50px;
    overflow: hidden;
    margin: 0 auto;
  }

  .PE_img-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    // display: flex;
    // overflow-x: scroll;
  }

  .PE_img-container ul {
    width: fit-content;
    white-space: nowrap;
    display: flex;
    position: relative;
    overflow: visible;
  }

  .PE_listl {
    width: 170px;
    padding-left: 10px;
  }

  .PE_listl img {
    width: 100%;
    height: 100%;
  }

  .PE_pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .PE_pagination el-button {
    margin: 0 10px;
  }

  .PC_father {
    display: none;
  }

  .left11 {
    width: 100% !important;
  }

  .center {
    width: 100% !important;
  }

  .tit {
    margin-bottom: 0px !important;
  }

  .company_profile {
    margin-top: 20px !important;
  }

  .img {
    width: 75% !important;
    height: 100vw !important;
    margin-left: 12.5%;
    margin-right: 12.5%;
  }

  #rongyu {
    width: 100% !important;
  }

  .honor .right {
    width: 92% !important;
    margin-left: 0px !important;
    margin-top: 0px !important;
    margin-left: 4% !important;
  }

  .development {
    padding-top: 20px !important;
  }

  .honor {
    margin-top: 20px !important;
  }

  .honor .right .bott div {
    width: 30% !important;
  }

  .honor .right .cen h2 {
    text-align: center !important;
  }

  .honor .right .cen {
    text-align: center;
  }

  .top1 {
    flex-direction: column !important;
    height: 100% !important;
    margin-top: 20px !important;
  }

  .top1 .detailleft {
    width: 100% !important;
  }

  .top1 .imgtop {
    width: 100% !important;
    margin-top: 20px;
  }

  .top1 .imgtop img {
    display: block;
  }

  .conncetuss {
    margin: 0 auto;
    width: 85% !important;
    padding-bottom: 0px !important;
    padding-top: 0px;
  }

  .honor .right .top {
    margin-top: 20px;
  }
}


#map-container{
  overflow: hidden;
  width: 100%;
  height: 400px;
  margin: 0;
}
.custom-content-marker {
  position: relative;
  width: 25px;
  height: 34px;
}

.custom-content-marker img {
  width: 100%;
  height: 100%;
}

.custom-content-marker .close-btn {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background: #ccc;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 15px;
  box-shadow: -1px 1px 1px rgba(10, 10, 10, .2);
}

.custom-content-marker .close-btn:hover{
  background: #666;
}</style>
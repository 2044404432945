import Vue from 'vue'
import Vuex from 'vuex'
import router, {resetRouter} from "@/router/index";  
  
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        globalVariable: 'example',
        currentPathName: '',
        work_id:null,
        menu:[],
        menu2:null,
        newid:null,
        selectnum:'11',
        productnaume:'',
        downloadFilepath:"",
        flag:null,
        lang:"zh_CN",
        aboutus:"",
    },
    mutations: {
       
        setPath(state) {
            state.currentPathName = localStorage.getItem("currentPathName")
        },
        logout() {
            // 清空缓存
            localStorage.removeItem("user")
            localStorage.removeItem("menus")
            router.push("/login")
            // 重置路由
            resetRouter()
        },
        workdetial(state,data){
            state.work_id=data;
        },
        menus(state,data){
            state.menu=data;
        },
        id_menu2(state,data){
            state.menu2=data;
        },
        newsid(state,data){
            state.newid=data;
        },
        selectNum(state,data){
            state.selectnum=data;
        },
        setproductnaume(state,data){
            state.selectnum=data;
        },
        // 存储白皮书/单页路径
        downloadPath(state,data){
            state.downloadFilepath=data;
        },
        // 提交信息完成后弹窗关闭
        flagdialog(state,data){
            state.flag=data;
        },
        langChange(state,data){
            state.lang=data;
        },
        aboutUs(state,data){
            state.aboutus=data;
        }
   
    }
})

export default store
// export  new Vuex.Store({  
//     state: {  
//       globalVariable: 'example', // 全局变量  
//     },  
//   });
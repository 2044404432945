<template>
  <!-- logo+公司名称 -->
  <div class="header" style="width: 100%;">
    <div class="ImgLogo">
      <img :src="logo" alt="" style="width: 50px;vertical-align: middle;" />
      <span style="color: aliceblue;font-size:20px;">
        {{ companydetail.companyName }}
      </span>
    </div>
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" active-text-color="rgb(116, 244, 252)" router>
      <!-- 导航按钮 -->
      <div v-for="item in frontMenus" :key="item.id" style="float: left; line-height: 90px" class="PEtest">
        <!-- 无下拉菜单 -->
        <div v-if="item.path" style="float: left">
          <el-menu-item :index="item.path" style="line-height: 90px" @click="menuclick(item)" :style="{ color: '#fff', 'background-color': isHovered && item.id == colo ? '' : '' }">
            <div @mouseenter="changeColor('rgb(116, 244, 252)', item.id)" @mouseleave="changeColor('', '')" :style="{ color: item.id == colo ? textColor : '' }">
              <i :class="item.icon"></i>
              <span slot="title">{{ item.name }}</span>
            </div>
          </el-menu-item>
        </div>
        <div v-else>
          <el-submenu :index="item.id + ''" :style="{ 'background-color': isHovered && item.id == colo ? '' : '' }">
            <template slot="title">
              <div @mouseenter="changeColor('rgb(116, 244, 252)', item.id)" @mouseleave="changeColor('', '')" :style="{ color: item.id == colo ? textColor : '' }">
                <i :class="item.icon"></i>
                <span slot="title">{{ item.name }}</span>
              </div>
            </template>
            <div v-for="subItem in item.children" :key="subItem.id">
              <el-menu-item ref="meauItem" :index="subItem.path" @click="menuclick(subItem)" :style="{ 'background-color': isHovered && item.id == colo ? '' : '' }">
                <div @mouseenter="changeColor('rgb(116, 244, 252)', item.id)" @mouseleave="changeColor('', '')" :style="{ color: item.id == colo ? textColor : '' }">
                  <i :class="subItem.icon"></i>
                  <span slot="title" style="color:#666">{{
                    subItem.name
                  }}</span>
                </div>
              </el-menu-item>
            </div>
          </el-submenu>
        </div>
      </div>
    </el-menu>
    <div style="width: 100px; float: right; line-height: 90px; margin-right: 45px" class="PEchinese">
      <button @click="changeLang" :class="{ 'active': currentLang === 'zh_CN' }" :style="{ color: currentLang === 'zh_CN' ? 'rgb(116, 244, 252)' : '#fff' }">中文</button>
      <span style="color:#fff">|</span>
      <button @click="changeLang" :class="{ 'active': currentLang === 'en_US' }" :style="{ color: currentLang === 'en_US' ? 'rgb(116, 244, 252)' : '#fff' }">&nbsp;&nbsp;En</button>
    </div>
    <!-- PE -->
    <div class="container-mobile">
      <div class="right-content">
        <div class="languages">
          <button @click="changeLang" :class="{ 'active': currentLang === 'zh_CN' }" :style="{ color: currentLang === 'zh_CN' ? 'rgb(116, 244, 252)' : '#fff' }">中文</button>
          <span style="color:#fff">|</span>
          <button @click="changeLang" :class="{ 'active': currentLang === 'en_US' }" :style="{ color: currentLang === 'en_US' ? 'rgb(116, 244, 252)' : '#fff' }">&nbsp;&nbsp;En</button>
        </div>
        <div class="menu">
          <el-button type="primary" icon="el-icon-s-fold" circle @click="switchover"></el-button>
          <div class="PE_navigation">
            <el-menu :default-active="activeIndex" class="el-menu-vertical-demo" @select="handleSelect" background-color="#545c64" text-color="#fff" router active-text-color="#ffd04b">
              <!-- 导航按钮 -->
              <div v-for="item in frontMenus" :key="item.id">
                <!-- 无下拉菜单 -->
                <div v-if="item.path">
                  <el-menu-item :index="item.path" @click="menuclick(item)">
                    <div @mouseenter="changeColor('rgb(116, 244, 252)', item.id)" @mouseleave="changeColor('', '')">
                      <i :class="item.icon"></i>
                      <span slot="title">{{ item.name }}</span>
                    </div>
                  </el-menu-item>
                </div>
                <div v-else>
                  <el-submenu :index="item.id + ''">
                    <template slot="title">
                      <div @mouseenter="changeColor('rgb(116, 244, 252)', item.id)" @mouseleave="changeColor('', '')">
                        <i :class="item.icon"></i>
                        <span slot="title">{{ item.name }}</span>
                      </div>
                    </template>
                    <div v-for="subItem in item.children" :key="subItem.id">
                      <el-menu-item ref="meauItem" :index="subItem.path" @click="menuclick(subItem)">
                        <div @mouseenter="changeColor('rgb(116, 244, 252)', item.id)" @mouseleave="changeColor('', '')">
                          <i :class="subItem.icon"></i>
                          <span slot="title">
                            {{ subItem.name }}
                          </span>
                        </div>
                      </el-menu-item>
                    </div>
                  </el-submenu>
                </div>
              </div>
            </el-menu>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      style="width: 100px; float: right; line-height: 90px; margin-right: 45px"
    >
      <el-input
        v-model="front_text"
        @keyup.enter.native="search"
        placeholder="搜索"
      ></el-input>
    </div> -->
  </div>
</template>

<script>
import cookies from 'js-cookie'
import { throttle } from 'lodash'
import i18n from '../../lang'
export default {
  // name:Head,
  data() {
    return {
      currentLang: 'zh_CN',
      colo: ' ',
      textColor: '#fff',
      frontMenus: [],
      isHovered: false,
      // frontMenus: localStorage.getItem("frontMenus") ? JSON.parse(localStorage.getItem("frontMenus")) : [],
      activeIndex: '1',
      activeIndex2: '1',
      front_text: '',
      logo: '',
      companydetail: {}
    }
  },
  mounted() {
    document.addEventListener('click', (event) => {
      if (event.target.className == 'el-icon-s-fold') {
        return
      }
      const target = event.target || event.srcElement
      // 逐级向上查找父级元素，直到找到具有指定class的元素
      let parent = target.parentElement
      while (parent && !parent.classList.contains('PE_navigation')) {
        parent = parent.parentElement
      }
      // 输出找到的父级元素的class
      if (parent == null) {
        // console.log(parent.className)
        document.querySelector('.PE_navigation').style.display = 'none'
      }
    })
    this.currentLang = localStorage.getItem('langChange')
    this.frontMenus = JSON.parse(localStorage.getItem('frontMenus1'))
    // console.log(this.frontMenus)
    this.huoqu()
  },
  watch: {
    $route(to, from) {
      // console.log("当前页面路由：" + to.path);
      if (to.path != '/searchresult') {
        this.front_text = ''
      }
    }
  },
  methods: {
    switchover() {
      let menu = document.querySelector('.PE_navigation')
      // console.log(menu)
      // console.log(menu.style)
      if (menu.style.display == 'block') {
        menu.style.display = 'none'
      } else {
        menu.style.display = 'block'
      }
    },
    changeColor(color, colo) {
      this.colo = colo
      this.textColor = color
      this.isHovered = color !== ''
    },
    // 获取当前的导航栏
    getHeader() {
      this.request({
        url: '/menu/getTouristMenu',
        method: 'post'
      }).then((res) => {
        if (res.code == 200) {
          localStorage.setItem('frontMenus1', JSON.stringify(res.data))
          this.frontMenus = JSON.parse(localStorage.getItem('frontMenus1'))
          // this.$forceUpdate()
          // if (localStorage.getItem('currentPathName') == 'newsDetail') {
          this.$router.push('/home')
          // }
          console.log('导航栏', res)
        } else {
          this.$message.error('保存失败')
        }
      })
    },
    changeLang: throttle(function (e) {
      if (e.target.innerHTML == '中文') {
        if (this.currentLang !== 'zh_CN') {
          this.currentLang = 'zh_CN'
          this.$store.commit('langChange', 'zh_CN')
          i18n.locale = 'zh'
          cookies.set('locale', 'zh')
          localStorage.setItem('langChange', 'zh_CN')
          this.getHeader()
          setTimeout(() => {
            window.location.reload(true)
          }, 100) // 100 毫秒延迟
        }
      } else {
        if (this.currentLang !== 'en_US') {
          this.currentLang = 'en_US'
          i18n.locale = 'en'
          cookies.set('locale', 'en')
          localStorage.setItem('langChange', 'en_US')
          this.getHeader()
          setTimeout(() => {
            window.location.reload(true)
          }, 100) // 100 毫秒延迟
        }
      }
    }, 5000),
    huoqu() {
      this.request.get('/company/selectParentCompany').then((res) => {
        // this.options = res.data
        if (res.code == '200') {
          // console.log('111img')
          this.companydetail = res.data
          this.logo = this.companydetail.companyLogo
          // console.log("111img", res);
        }
      })
    },

    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    menuclick(item) {
      // location.reload()

      this.$store.commit('setproductnaume', item.name)
      // 菜单项的ID
      localStorage.setItem('iddMenu', item.id)

      if (item.name == '资质荣誉' || item.name == 'Qualification Honor') {
        this.$store.commit('aboutUs', 'rongyu')
      } else if (item.name == '联系我们' || item.name == 'Contact Us') {
        this.$store.commit('aboutUs', 'connect')
      } else if (item.name == '公司概述' || item.name == 'Overview') {
        this.$store.commit('aboutUs', 'gaishu')
      } else if (item.name == '发展历程' || item.name == 'Milestions') {
        this.$store.commit('aboutUs', 'develop')
      }
      if (item.name == '企业新闻') {
        this.$store.commit('id_menu2', '11')
        // console.log("企业新闻",item.name,this.$store.state.menu2)
      } else if (item.name == '社会责任') {
        this.$store.commit('id_menu2', '12')
      } else if (item.name == '行业资讯') {
        this.$store.commit('id_menu2', '13')
      } else if (item.name == '企业文化') {
        this.$store.commit('id_menu2', '14')
      }
      let menu = document.querySelector('.PE_navigation')
      menu.style.display = 'none'
      // console.log("菜单",item)
    },
    search(e) {
      // console.log("搜索");
      this.$router
        .push({
          path: '/searchresult',
          query: { text: this.front_text }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
.el-menu-demo {
  /deep/.el-submenu__title i {
    display: none;
  }
}
.container-mobile {
  width: 100%;
  height: 90px;
  color: #fff;
}

.right-content {
  display: flex;
  justify-content: flex-end;
  //align-items: center;
  height: 100%;
}

.languages {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;
  margin-top: 0px;
  z-index: 999;
}

.menu {
  position: absolute;
  height: 28px;
  line-height: 28px;
  vertical-align: middle;
  right: 0;
  top: 26px;
  position: absolute;
}

.PE_navigation {
  position: fixed;
  top: 75px;
  left: 0;
  min-width: 130px;
  height: 500px;
  overflow: auto;
  display: none;

  /deep/.el-menu-item {
    height: 50px;
  }

  /deep/.el-submenu__title {
    line-height: 50px !important;
    height: 50px !important;
  }
}

//移动端适配
@media (max-width: 668px) {
  /deep/.el-header {
    height: 75px !important;
  }

  .header {
    width: 100%;
    height: 75px;
    font-size: 14px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    background: linear-gradient(to right, rgb(29, 27, 111), rgb(61, 59, 155));
  }

  .ImgLogo {
    position: absolute;
    right: 0;
  }

  .languages {
    position: absolute;
    left: 40px;
    top: 30px;
  }

  .menu {
    left: 0;
  }

  .PEtest {
    display: none;
  }

  .PEchinese {
    display: none;
  }

  .PE_navigation {
    display: none;
  }

  .container-mobile {
    display: block;
  }
}

@media (min-width: 668px) {
  /deep/.el-menu {
    border-right: 0px;
  }

  .header {
    width: 100%;
    height: 90px;
    font-size: 14px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    background: linear-gradient(to right, rgb(29, 27, 111), rgb(61, 59, 155));
    min-width: 900px;
  }

  .PEtest {
    display: block;
  }

  .PE_navigation {
    display: none;
  }

  .container-mobile {
    display: none;
  }
}

button {
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  font-size: 12px;
  padding-right: 5px;
  cursor: pointer;
}

.active {
  /* 根据需要定义选中状态下的样式 */
  font-weight: bold;
  /* 可以添加其他样式 */
}

* {
  font-family: '普惠字体';
}

/deep/.el-menu--horizontal {
  background-color: transparent !important;
  color: #fff !important;
}

/deep/.el-submenu .el-submenu__title {
  color: #fff !important;
}

/deep/.el-submenu__title {
  color: #fff !important;
}

/deep/.el-submenu__title:hover {
  background-color: transparent !important;
}

/deep/.el-menu.el-menu--horizontal {
  border-bottom: 0px !important;
}

/deep/.el-submenu__title {
  line-height: 90px !important;
  height: 90px !important;
}

/deep/.el-menu-item {
  height: 90px;
}

/deep/.el-menu-item:hover {
  // height: 90px;
  background-color: transparent;
}

/deep/.el-menu-item:focus {
  // height: 90px;
  background-color: transparent;
}

.el-menu-demo {
  width: auto;
  float: left;
  margin-left: 7vw;
  line-height: 90px;
  height: 90px;
}

.ImgLogo {
  float: left;
  margin-right: 20px;
  line-height: 90px;
  margin-left: 140px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1000px) {
  .el-menu-demo {
    margin-left: 10px;
  }

  .ImgLogo {
    margin-left: 40px;
  }
}
</style>
<template>
  <div>
    <el-carousel height="30vw" :interval="5000" arrow="hover">
      <el-carousel-item v-for="(item, index) in imgs" :key="index">
        <img ref="bannerImg" :src="item.url" alt="carousel-image" style="cursor: pointer; width: 100%; height: 100%"
          @click="navigateTo(item)" />
      </el-carousel-item>
    </el-carousel>
    <!-- 企业服务 -->
    <!-- <div class="enterprise">
            <div class="center">
                <div style="padding-top:50px ;padding-bottom:40px">
                    <h3 class="font_size" style="text-align:center">ENTERPRISE SERVICE</h3>

        <div style="clear: both" class="tit">
          <span class="xian"></span>
          <h1 class="font_size">企业服务</h1>
          <span class="xian"></span>
        </div>
                </div>
                <div class="text_1">
                    <ul  style="display: flex; justify-content: space-between;">
                        <li
                        v-for="item in Comserve" :key="item.id"
                         style=" width:254px;height:320px;float:left;list-style:none;">
                          <div style="width:100%;height:170px;">
                            <img :src="item.titleImg" alt="" style="padding:10px 35px;width:100%;height:100%">
                          </div>
                          <div>
                            <h4 style=" color: rgb(18,79,181);text-align:center;font-size:16px">{{ item.name }}</h4>
                            <ul style="text-align:center;padding-top:20px;" id="ul1">
                                <li v-for="i in item.contents"
                                :key="i.id"
                            style="list-style:none;text-align:left;padding-left:35px;line-height:24px;font-size:14px">. {{ i }}</li>
                            </ul>
                          </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div> -->

    <div class="hot_product">
      <!-- <div class="center_OVE">
        <span style="font-size:14px;cursor:pointer;" @click="toHome">{{ $t("global.home") }}&gt;</span>
        <span>{{ $t('global.About_us') }}</span>
        <div class="company_profile">
          <h2 style="text-align: center;">OVERVIEW</h2>
          <div style="clear: both" class="tit_OVE">
            <span class="xian"></span>
            <h1 id="gaishu" ref="gaishu">{{ $t('global.gongsi') }}</h1>
            <span class="xian"></span>
          </div>
          <div class="big_OVE">
          <div class="left11" style="width: 60%; float: left; max-width:500px">
            <mavon-editor class="md" :value="companyDetail.companyDescription" :subfield="false" :defaultOpen="'preview'"
              :toolbarsFlag="false" :editable="false" :scrollStyle="true" :ishljs="true" />
          </div>
          <div class="img" style="width: 33%; float: right; height: 17vw;max-height:210px;margin-top: 31px;">
            <img v-lazy="imgObj" :src="companyDetail.companyDescriptionImg" alt="" style="width: 100%; height: 100%" />
          </div>
        </div>
        </div>
      </div> -->
      <!--  -->
      <div class="center">
        <div style="padding-top: 100px; ">
          <h3 style="text-align: center" class="font_size" v-if="$t('global.chanpin') != 'HOT PRODUCT'">HOT PRODUCT</h3>
          <div style="clear: both" class="tit">
            <span class="xian"></span>
            <h1 class="font_size">{{ $t("global.chanpin") }}</h1>
            <span class="xian"></span>
          </div>
        </div>
        <ul class="ul_list">
          <li @click="enterdetail(item)" style="
              width: 210px;
              height: 190px;
              list-style: none;
              margin-bottom: 10px;
            " v-for="item in products" :key="item.id">
            <img v-if="item.showImg" :src="item.showImg" alt="" />
            <p style="font-size: 16px">{{ item.name }}</p>
          </li>
        </ul>
      </div>
    </div>

    <!-- 移动端 -->
    <div class="PE_product">
      <div class="center">
        <div class="center_OVE">
          <div class="company_profile">
            <h2 v-if="$t('global.gongsi') != 'OVERVIEW'" style="text-align: center;">OVERVIEW</h2>
            <div style="clear: both" class="tit_OVE">
              <span class="xian"></span>
              <h1 id="gaishu" ref="gaishu">{{ $t('global.gongsi') }}</h1>
              <span class="xian"></span>
            </div>
            <div class="big_OVE">
            <div class="left11" style=" float: left;">
              <mavon-editor class="md" :value="companyDetail.companyDescription" :subfield="false" :defaultOpen="'preview'"
                :toolbarsFlag="false" :editable="false" :scrollStyle="true" :ishljs="true" />
            </div>
            <div class="img" style="width: 33%; float: right; height: 17vw;max-height:210px;margin-top: 31px;">
              <img v-lazy="imgObj" :src="companyDetail.companyDescriptionImg" alt="" style="width: 100%; height: 100%" />
            </div>
          </div>
          </div>
        </div>
        <!--  -->
        <div style="padding-top: 40px; padding-bottom:40px">
          <h3 style="text-align: center" class="font_size" v-if="$t('global.chanpin') != 'HOT PRODUCT'">HOT PRODUCT</h3>
          <div style="clear: both" class="tit">
            <span class="xian"></span>
            <h1 class="font_size">{{ $t("global.chanpin") }}</h1>
            <span class="xian"></span>
          </div>
        </div>
        <ul class="ul_list">
          <li class="PE_li" @click="enterdetail(item)" v-for="item in products" :key="item.id">
            <img v-if="item.showImg" :src="item.showImg" alt="" />
            <p style="font-size: 14px">{{ item.name }}</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- <div class='Global_center' style="width:100%">
      <div style="padding-top: 50px; padding-bottom: 30px">
          <h3 style="text-align: center" class="font_size">
           R&D CENTER
          </h3>
          <div style="clear: both" class="tit">
            <span class="xian"></span>
            <h1 class="font_size">全球研发中心</h1>
            <span class="xian"></span>
          </div>
        </div>
      <img  :src="gloBalImg" style='width:100%;height:100%'>
    </div> -->
    <!-- PC -->
    <div class="news_center">
      <div class="center">
        <div style="padding-top: 50px; padding-bottom: 50px">
          <h3 style="text-align: center" class="font_size" v-if="$t('global.News') != 'NEWS'">
            NEWS
          </h3>
          <div style="clear: both" class="tit">
            <span class="xian"></span>
            <h1 class="font_size">{{ $t("global.News") }} </h1>
            <span class="xian"></span>
          </div>
        </div>
        <div class="news" style="margin: 0 auto; height: 350px">
          <div class="left" style="width: 300px; height: 350px; float: left">
            <div class="top" style="width: 100%; height: 225px" @click="newsDetails(objnews)">
              <img :src="objnews.imageUrl" alt="" style="width: 100%; height: 100%" />
            </div>
            <div class="bottom" style="
                width: 100%;
                height: 100px;
                background-color: rgb(224, 224, 224);
                padding: 5px;
              " @click="newsDetails(objnews)">
              <h4>{{ objnews.name }}</h4>
              <p style="font-size: 12px;" v-if="newsList.length > 0">
                {{ objnews.summarize }}
              </p>
            </div>
          </div>
          <ul class="right" style="
              width: 570px;
              height: 350px;
              list-style: none;
              float: left;
              margin-left: 20px;
            ">
            <li v-if="newsList.length > 0" @click="newsDetails(newsList[1])" style="
                width: 100%;
                height: 100px;
                background-color: rgb(224, 224, 224);
                padding: 10px;
              ">
              <div class="right_left">
                <h3>{{ timeA[0] }}</h3>
                <span>{{ objTime[0] }}</span>
              </div>
              <div class="rihgt_rig">
                <h4 v-if="newsList.length > 0">{{ newsList[1].name }}</h4>
                <p style="font-size: 12px" v-if="newsList.length > 0">
                  {{ newsList[1].summarize }}
                </p>
              </div>
            </li>
            <li v-if="newsList.length > 0" @click="newsDetails(newsList[2])" style="
                width: 100%;
                height: 100px;
                background-color: rgb(224, 224, 224);
                padding: 10px;
              ">
              <div class="right_left">
                <h3>{{ timeA[1] }}</h3>
                <span>{{ objTime[1] }}</span>
              </div>
              <div class="rihgt_rig">
                <h4 v-if="newsList.length > 0">{{ newsList[2].name }}</h4>
                <p style="font-size: 12px" v-if="newsList.length > 0">
                  {{ newsList[2].summarize }}
                </p>
              </div>
            </li>
            <li v-if="newsList.length > 0" @click="newsDetails(newsList[3])" style="
                width: 100%;
                height: 100px;
                background-color: rgb(224, 224, 224);
                padding: 10px;
              ">
              <div class="right_left">
                <h3>{{ timeA[2] }}</h3>
                <span>{{ objTime[2] }}</span>
              </div>
              <div class="rihgt_rig">
                <h4 v-if="newsList.length > 0">{{ newsList[3].name }}</h4>
                <p style="font-size: 12px" v-if="newsList.length > 0">
                  {{ newsList[3].summarize }}
                </p>
              </div>
            </li>
          </ul>
        </div>
        <p style="font-size: 12px; display: block; width: 880px; margin: 0 auto" @click="gengduo">
          {{ $t('global.more') }}&gt;&gt;
        </p>
      </div>
    </div>
    <!-- PE -->
    <div class="PE_father">
      <div class="PE_news_center">
        <div class="center">
          <h3 class="font_size" style="margin-bottom: -30px;" v-if="$t('global.News') != 'NEWS'">
            NEWS
          </h3>
          <div class="tit" style="margin-top:30px">
            <span class="xian"></span>
            <h1 class="font_size">{{ $t("global.News") }} </h1>
            <span class="xian"></span>
          </div>
          <div class="news">
            <div class="left">
              <div class="top" @click="newsDetails(objnews)">
                <img :src="objnews.imageUrl" alt="" style="width: 100%; height: 100%" />
              </div>
              <div class="bottom" @click="newsDetails(objnews)">
                <h4>{{ objnews.name }}</h4>
                <p v-if="newsList.length > 0">{{ objnews.summarize }}</p>
              </div>
            </div>
            <ul class="right">
              <li v-if="newsList.length > 0" @click="newsDetails(newsList[1])">
                <div class="right_left">
                  <h3>{{ timeA[0] }}</h3>
                  <span>{{ objTime[0] }}</span>
                </div>
                <div class="right_rig">
                  <h4 v-if="newsList.length > 0">{{ newsList[1].name }}</h4>
                  <p v-if="newsList.length > 0">{{ newsList[1].summarize }}</p>
                </div>
              </li>
              <li v-if="newsList.length > 0" @click="newsDetails(newsList[2])">
                <div class="right_left">
                  <h3>{{ timeA[1] }}</h3>
                  <span>{{ objTime[1] }}</span>
                </div>
                <div class="right_rig">
                  <h4 v-if="newsList.length > 0">{{ newsList[2].name }}</h4>
                  <p v-if="newsList.length > 0">{{ newsList[2].summarize }}</p>
                </div>
              </li>
              <li v-if="newsList.length > 0" @click="newsDetails(newsList[3])">
                <div class="right_left">
                  <h3>{{ timeA[2] }}</h3>
                  <span>{{ objTime[2] }}</span>
                </div>
                <div class="right_rig">
                  <h4 v-if="newsList.length > 0">{{ newsList[3].name }}</h4>
                  <p v-if="newsList.length > 0">{{ newsList[3].summarize }}</p>
                </div>
              </li>
            </ul>
          </div>
          <p @click="gengduo">{{ $t('global.more') }}&gt;&gt;</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrontHome",
  data() {
    return {
      imgObj:"",
      companyDetail:{},
      gloBalImg: "",
      arrMeaulist: [],
      bannerHeight: "",
      Comserve: [],
      products: [],
      imgg: [],
      imgs: [],
      files: [],
      hotProduct: [
        {
          imgurl: "",
          name: "原型验证",
          menuid: "35",
        },
        {
          imgurl: "@/assets/22.png",
          name: "汤谷天元云",
          menuid: "36",
        },
        {
          imgurl: "@/assets/23.png",
          name: "EDA云底座",
          menuid: "37",
        },
        {
          imgurl: "@/assets/4.png",
          name: "一站式高性能IP芯片",
          menuid: "38",
        },
      ],
      newsList: [],
      objnews: {},
      timeA: [],
      objTime: [],
      Comserve: null,
      meaulist: [],
    };
  },
  mounted() {
    this.gongsigaishu()
    this.product();
    // this.qiyefuwu();
    // this.getGlobl();
    this.$nextTick(() => {
      window.scrollTo(0, 0);
    });
    this.meaulist = JSON.parse(localStorage.getItem("frontMenus1"));
    // console.log('列表',this.meaulist)
    localStorage.setItem("iddMenu", 13);
    this.request
      .get("/FlatPattern/getMenuById", {
        params: {
          id: localStorage.getItem("iddMenu"),
        },
      })
      .then((res) => {
        // console.log("轮播图", res);
        this.imgs = res.data.flatPattern;
        this.newsCenter();
      });
  },
  methods: {
    gongsigaishu() {
      this.request.get('/company/selectParentCompany').then((res) => {
        if (res.code == '200') {
          this.companyDetail = res.data
          this.imgObj = this.companyDetail.companyDescriptionImg
          console.log("母公司",this.companyDetail)
        }
      })
    },
    toHome() {
      this.$router.push('/home')
    },

    getGlobl() {
      this.request({
        url: "/PictureViewer/list",
        method: "post",
        data: {
          name: "全球研发中心",
        }
      }).then((res) => {
        console.log("欢迎来到汤谷智能官网", res);
        // this.dialogGlobal=false;
        if (res.data) {
          this.gloBalImg = res.data[0].titleImg
        }

        // console.log("获取链接",res.data[0].titleImg,this.gloBalImg)
      })
    },
    navigateTo(item1) {
      let arrMeau = this.meaulist;
      arrMeau.forEach((item) => {
        // console.log("121", item);
        if (item.name == "产品服务" || item.name == "Products & Services") {
          this.arrMeaulist = item.children;
          this.arrMeaulist.forEach((i) => {
            if (i.id == item1.accessPath) {
              localStorage.setItem("iddMenu", item1.accessPath);
              this.meauRoute = i.path;

              this.$router.push({ path: i.path });


            }
          });
        }
      });
      if (url) {
        window.location.href = url;
      }
    },

    gengduo() {
      localStorage.setItem("iddMenu", 25);
      this.$store.commit("id_menu2", "11");
      this.$router.push("/Companynews");

    },
    // 企业服务
    // qiyefuwu() {
    //   let arr = ["1", "1", "1"];
    //   let arr1 = JSON.stringify(arr);

    //   // 查询企业服务
    //   this.request.post("/CompanyService/list").then((res) => {
    //     if (res.code == 200) {
    //       this.Comserve = res.data;
    //     }
    //   });
    // },
    // 产品服务
    product() {
      this.request.get("/product/queryTopServices").then((res) => {
        if (res.code) {
          // console.log("产品服务", res.data);
          this.products = res.data;
        }
      });
    },
    // 进入新闻资讯详情页
    newsDetails(e) {
      localStorage.setItem("newsId", e.id);
      this.$router.push({ path: "/newsdetail" });
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },

    newsCenter() {
      this.request
        .post("/article/pageList", { pageSize: 4, pageNum: 1, articleType: 11 })
        .then((res) => {
          if (res.code == 200) {
            this.newsList = res.data;
            this.objnews = res.data[0];
            console.log(this.newsList);
            this.newsList.forEach((item, index) => {
              if (index != 0) {
                this.objTime.push(
                  item.releaseTime.split("-")[0] +
                  "-" +
                  item.releaseTime.split("-")[1]
                );
                this.timeA.push(item.releaseTime.split("-")[1]);
              }
            });
          }
        });
    },
    // 产品服务
    enterdetail(item1) {
      let arrMeau = this.meaulist;
      arrMeau.forEach((item) => {
        // console.log("121", item);
        if (item.name == "产品服务" || item.name == "Products & Services") {
          this.arrMeaulist = item.children;
          this.arrMeaulist.forEach((i) => {
            if (i.id == item1.path) {
              localStorage.setItem("iddMenu", item1.path);
              this.meauRoute = i.path;
              this.$router.push({ path: i.path });
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  font-family: '普惠字体' !important;
  list-style-type: none;
}
.tit_OVE{
  height: 50px;
    line-height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.big_OVE{
  margin-top: 50px;
}
.left11 {
  width: 100% !important;
}
.company_profile {
  margin-top: 20px !important;
}
.center_OVE {
  width: 75%;
  max-width: 913px;
  overflow: hidden;
  clear: both;
  margin: 0 auto;
  padding-top: 20px;

  >span {
    font-size: 14px;
  }
}

.tit {
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  h1 {
    display: inline;
  }

  .xian {
    display: inline-block;
    width: 36px;
    height: 4px;
    background-color: rgb(18, 79, 181);
  }
}

h1 {
  display: inline;
}

.xian {
  display: inline-block;
  width: 36px;
  height: 4px;
  background-color: rgb(8, 8, 161);
}

.news {
  display: flex;
  justify-content: center;
}

.left {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .right_left {
    float: left;
    width: 90px;
    height: 100%;
    background-color: rgb(18, 79, 181);
    color: #fff;
    text-align: center;
    line-height: 40px;
  }

  .rihgt_rig {
    cursor: pointer;
    width: ceil(100%-20vh);
    height: 100%;
    float: left;
    margin-left: 10px;
    color: #333;

    p {
      width: 100%;
      height: auto;
      word-wrap: break-word;
      word-break: break-all;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      margin-top: 10px;
    }

    h4 {
      width: 100%;
      height: auto;
      word-wrap: break-word;
      word-break: break-all;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
    }
  }
}

.text_1 {
  ul {
    li {
      list-style: disc;
    }

    li:hover {
      border: 2px solid rgb(6, 71, 202);
    }
  }
}

.enterprise {
  width: 100%;
  height: 535px;
  background-image: url(@/assets/bgc_enterprise.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .center {
    width: 70vw;
    margin: auto;
    height: 535px;
  }
}


.hot_product {
  width: 100%;

  .center {
    width: 900px;
    margin: auto;
    padding-bottom: 50px;
  }
}

.news_center {
  width: 100%;
  height: 800px;
  background-color: rgb(234, 234, 234);

  .center {
    width: 950px;
    margin: auto;
    height: 670px;

    >p {
      text-align: right;
      cursor: pointer;
    }
  }
}

.ul_list {
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
  padding-top: 80px;
  // float:left;
  width: 100%;
  overflow: auto;

  li:hover {
    transform: translateY(-5px);
  }

  li {
    background-color: #124fb5;
    width: 210px;
    height: 190px;
    float: left;
    margin-right: 10px;
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.3);

    p {
      text-align: center;
      font-size: 16px;
      color: #ffffff;
      font-weight: 600;
    }

    img {
      display: block;
      width: 190px;
      height: 160px;
      margin: 0 auto;
    }
  }

  li:hover {
    // background-color: rgb(18,79,181);
    // background-color: rgb(30,28,112);
    cursor: pointer;
  }
}

.bottom {
  padding: 8px;
  margin-top: 20px;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;

  h4 {
    width: 100%;
    height: auto;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
  }

  p {
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
  }
}

#ul1,
#ul2,
#ul3 {
  li {
    list-style: none;
    border: 0px solid rgb(0, 0, 0, 0);
    cursor: pointer;
  }
}

.font_size {
  font-size: 22px;
}

@media (min-width: 668px) {
  .PE_product {
    display: none;
  }

  .PE_news_center {
    display: none;
  }
}

//移动端适配
@media (max-width: 668px) {
  .v-note-wrapper.shadow{
    box-shadow: 0 0 0 0 transparent!important;
  }
  .img{
    width: 75%!important;
    height: 100vw!important;
    margin-left: 12.5%;
    margin-right: 12.5%;
  }
  .center_OVE{
    width: 95%;
    padding-top: 20px;
  }
  .big_OVE{
    margin-top: 20px !important
  }
  .left11 {
    width: 100% !important;
  }
  .news_center {
    display: none;
  }

  .PE_father {

    .PE_news_center {
      width: 100%;
      height: auto;
      background-color: rgb(234, 234, 234);
    }

    .center {
      width: 100%;
      margin: auto;
      height: auto;
      padding: 10px;

      h3 {
        margin-top: 30px;
        margin-bottom: 10px;
        text-align: center;
        font-size: 22px;
      }

      .tit {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;

        .xian {
          height: 4px;
          background-color: #124fb5;
          width: 36px;
        }

        h1 {
          font-size: 22px;
          margin: 0 10px;
        }
      }

      .news {
        margin: auto;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px;

        .left {
          width: 100%;
          max-width: 300px;
          height: auto;
          margin-bottom: 20px;

          .top {
            width: 100%;
            height: auto;
          }

          .bottom {
            width: 100%;
            height: auto;
            padding: 10px;

            h4 {
              font-size: 16px;
              margin-top: 10px;
              margin-bottom: 5px;
            }

            p {
              font-size: 12px;
              margin: 0;
              text-align: justify;
            }
          }
        }

        .right {
          width: 100%;
          max-width: 570px;
          height: auto;

          li {
            width: 100%;
            height: auto;
            margin-bottom: 20px;
            padding: 10px;
            background-color: rgb(224, 224, 224);

            .right_left {
              justify-content: space-between;
              align-items: center;
              margin-bottom: 5px;

              h3 {
                font-size: 14px;
                margin: 0;
              }

              span {
                font-size: 12px;
              }
            }

            .right_rig {
              h4 {
                font-size: 16px;
                margin-top: 10px;
                margin-bottom: 5px;
                margin-left: 100px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                overflow: hidden;
              }
              
              p {
                padding-left: 10px;
                font-size: 12px;
                margin: 0;
                text-align: left;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }

      p {
        font-size: 12px;
        display: block;
        text-align: center;
        margin: 20px auto 0;
        cursor: pointer;
      }
    }
  }

  .hot_product {
    display: none;
  }

  .PE_li {
    height: 190px;

  }

  .PE_product {
    li {
      //margin-left: 10px;
      background-color: #124fb5;
      width: 110px;
      height: 90px;
      float: left;
      margin-right: 10px;
      box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.3);

      p {
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        font-weight: 600;
      }

      img {
        display: block;
        width: 170px;
        height: 140px;
        margin: 0 auto;
      }
    }


    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .ul_list {
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    .ul_list li {
      width: calc(50% - 25px);
      margin-left: 15px;
      height: 170px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }


}
</style>

import axios from 'axios'
import ElementUI from 'element-ui'
// import store from '../store/store'
import cookies from 'js-cookie'
const request = axios.create({
    baseURL:window.g.baseURL,
    // baseURL: 'https://www.macrone.com.cn:9090',
    // baseURL: 'http://192.168.0.39:9090',
    // baseURL: 'http://192.168.1.12:9090',
    // baseURL: 'https://www.tangoic.com:9090',
    timeout:window.g.timeout
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    // config.headers['Content-Language']=cookies.get('langChange');
   if(localStorage.getItem("langChange")) {
    config.headers['Content-Language']=localStorage.getItem("langChange");
   }else{
    config.headers['Content-Language']='zh_CN';
   }
    
// config.headers['Content-Language']='zh_CN';
    if (config.url === '/article/save') {
        config.headers['Content-Type'] = 'multipart/form-data';
    } else if (config.url === "/article/pageList") {
        config.headers['Content-Type'] = 'multipart/form-data';
    } else if (config.url === '/company/add') {
        config.headers['Content-Type'] = 'multipart/form-data';
    } else if (config.url === '/company/update') {
        config.headers['Content-Type'] = 'multipart/form-data';
    } else if (config.url === '/product/add') {
        config.headers['Content-Type'] = 'multipart/form-data';
    } 
    else if (config.url === '/product/update') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else if (config.url === '/comment/page') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else if (config.url === '/product/deleteProductButtons') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    // else if (config.url === '/product/addTopProduct') {
    //     config.headers['Content-Type'] = 'multipart/form-data';
    // }
    else if (config.url === '/FlatPattern/update') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else if (config.url === '/FlatPattern/insert') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else if (config.url === '/file/upload') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else if (config.url === '/article/pageList') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else if (config.url === '/CompanyInfoFile/insetrParentCompanyHonor') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else if (config.url === '/CompanyInfoFile/insert') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else if (config.url === '/CompanyService/add') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else if (config.url === '/CompanyService/update') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else if (config.url === '/file/upload') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else if (config.url === '/comment/findCommentUnreadCount') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    else if (config.url === '/comment/findCommentById') {
        config.headers['Content-Type'] = 'multipart/form-data';
    }
    //   else if (config.url === "/article/findOne") {
    //     config.headers['Content-Type'] = 'multipart/form-data';
    //   }
    let user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    if (user) {
        config.headers['token'] = user.token;  // 设置请求头
    }
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        // if (typeof res === 'string') {
        //     // res = res?JSON.parse(res):res
        //     if(JSON.parse(res)){
        //         res=JSON.parse(res)
        //     }else{
        //         res=res
        //     }
        // }
        // 当权限验证不通过的时候给出提示
        if (res.code == '401') {
            ElementUI.Message({
                message: res.msg,
                type: 'error'
            });
        }

        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)


export default request
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/store";
import axios from 'axios';
// import axios from 'axios'

import Front from "../views/front/manager.vue"
Vue.use(VueRouter)

const routes = [
    // 用户登陆页面
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    // 用户注册页面
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/404.vue')
    },
    // {
    //     path: '/admin',
    //     name: '后台',
    //     // redirect:"/home",
    //     // component: () => import('../views/404.vue')
    //     component: () => import('../views/Manage.vue')
    // },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// 提供一个重置路由的方法
export const resetRouter = () => {
    router.matcher = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes
    })
}

// 注意：刷新页面会导致页面路由重置
export const setRoutes = () => {
    const storeMenus = localStorage.getItem("menus");
    const frontmenus = JSON.parse(localStorage.getItem("frontMenus1"));
    if (storeMenus) {
        // 拼装动态路由
        const manageRoute = {
            path: '/admin',
            name: 'Manage',
            component:
                () => import('../views/Manage.vue')
            ,
            // redirect: "/home",
            children: [
                { path: 'person', name: '个人信息', component: () => import('../views/Person.vue') },
                { path: 'password', name: '修改密码', component: () => import('../views/Password.vue') }
            ]
        }

        const menus = JSON.parse(storeMenus)
        // console.log(menus)
        menus.forEach(item => {
            if (item.path) { // 当且仅当path不为空的时候才去设置路由
                let itemMenu = {
                    path: item.path,
                    name: item.name,
                    component: () => import('../views/' + item.pagePath + '.vue')
                }
                manageRoute.children.push(itemMenu)
            } else if (item.children.length) {
                item.children.forEach(item => {
                    if (item.path) {
                        let itemMenu = {
                            path: item.path,
                            name: item.name,
                            component: () => import('../views/' + item.pagePath + '.vue')
                        }
                        manageRoute.children.push(itemMenu)
                    }
                })
            }
        })

        // 获取当前的路由对象名称数组
        const currentRouteNames = router.getRoutes().map(v => v.name)
        if (!currentRouteNames.includes("Manage")) {
            // 动态添加到现在的路由对象中去
            router.addRoute(manageRoute)

        }
    }

    if (frontmenus) {

        const frontManage = {
            path: '/',
            name: 'frontManage',
            redirect: '/home',
            component:
                Front
            ,
            children: [
                {
                    path: '/articledetail',
                    name: 'articleDetail',
                    component: () => import("../views/front/joinUs/articledetial.vue"),
                    // redirect:'/home',
                    children: []
                },
                {
                    path: '/newsdetail',
                    name: 'newsDetail',
                    component: () => import("../views/front/newsCenter/Newsdetial.vue"),
                    // redirect:'/home',
                    children: []
                },
                {
                    path: '/techliteratural',
                    component: () => import("../views/front/Techliterature.vue"),
                    // redirect:'/home',
                    children: []
                },
                {
                    path: '/getmethods',
                    component: () => import("../views/front/Getmethods.vue"),
                    // redirect:'/home',
                    children: []
                },
                {
                    path: '/searchresult',
                    component: () => import("../views/front/SearchResult.vue"),
                    // redirect:'/home',
                    children: []
                }
            ]
        }
        // console.log("dsadsadsa",frontManage)
        const frontMenus = frontmenus
        // console.log("aqw",frontMenus)

        // 动态添加前端子路由
        frontMenus.forEach(item => {
            if (item.path) {
                let itemMenu = {
                    path: item.path,
                    name: item.name,
                    component: () => import('../views/front/' + item.pagePath + '.vue')
                }
                frontManage.children.push(itemMenu)
            } else if (item.children != null && item.children.length) {
                item.children.forEach(i => {
                    // console.log('qqq',i)
                    if (i.path) {
                        let itemchildMenus = {
                            path: i.path,
                            name: i.name,
                            component: () => import('@/views/front/' + i.pagePath + '.vue')
                        }
                        frontManage.children.push(itemchildMenus)
                        // console.log("aaa",frontManage) 
                    }
                })

            }
        })
        // 获取当前的路由对象名称数组
        const currentRouteName = router.getRoutes().map(v => v.name)
        if (!currentRouteName.includes("frontManage")) {
            // 动态添加到现在的路由对象中去
            router.addRoute(frontManage)

        }
    }
}

// 重置我就再set一次路由
setRoutes()

// 路由守卫
router.beforeEach((to, from, next) => {

    localStorage.setItem("currentPathName", to.name)  // 设置当前的路由名称，为了在Header组件中去使用
    store.commit("setPath")  // 触发store的数据更新
    // 未找到路由的情况
    if (!to.matched.length) {

        const storeMenus = localStorage.getItem("menus")
        var frontMenu = JSON.parse(localStorage.getItem("frontMenus1"))

        if (!JSON.parse(localStorage.getItem("frontMenus1"))) {
            // 1
            axios.post("/menu/getTouristMenu")
                .then(res => {
                    // 请求成功，处理响应数据
                    //   console.log('aaa',res.data.data);
                    localStorage.setItem("frontMenus1", JSON.stringify(res.data.data))
                    frontMenu = JSON.parse(localStorage.getItem("frontMenus1"))
                    setRoutes()
                })
                .catch(error => {
                    // 请求失败，处理错误信息
                    console.log(error);
                });

        }
        if (storeMenus) {
            next("/404")
        } else if (frontMenu) {

            next("/home")
        } else {
            // 跳回登录页面
            // next("/login")
        }
    }
    next()  // 放行路由
})

export default router
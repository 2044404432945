import Vue from 'vue';
import VueI18n from 'vue-i18n'
import elementuiEn from "element-ui/lib/locale/lang/en";
import elementuiZh from "element-ui/lib/locale/lang/zh-CN";
import en from './en'
import zh from './zh'

import cookies from 'js-cookie'

Vue.use(VueI18n);

const messages = {
    en: Object.assign(en, elementuiEn),
    zh: { ...zh, ...elementuiZh }
}

// localStorage.setItem('locale', 'en')


// console.log('huoqu',cookies.get('locale'))
const i18n = new VueI18n({
    locale: cookies.get('locale') || "zh",
    messages
})


export default i18n;
